<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div :hidden="true">
      {{ updateKey }}
    </div>
    <ConfirmDialog />
    <Dialog
      v-model:visible="displayProfileDeletionDialog"
      header="Profile deletion"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      :modal="true"
      @hide="closeProfileDeletion"
    >
      <p>
        Select the profiles you want to delete for account <strong>{{ selectedAccount.email }}</strong>
      </p>
      <div v-if="accountProfiles === null">
        <ProgressSpinner />
      </div>
      <MultiSelect
        v-else
        v-model="selectedProfiles"
        data-key="profileId"
        class="w-6"
        :options="accountProfiles"
        option-value="profileId"
        placeholder="Select profiles to delete..."
        :option-label="getProfileName"
        :filter="true"
        display="chip"
      >
        <template #option="slotProps">
          <span>{{ `${slotProps.option.name} - ${slotProps.option.countryCode}` }}</span><span class="entity-id">{{ `${slotProps.option.entityId.replace("ENTITY", "")}` }}</span>
        </template>
      </MultiSelect>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          autofocus
          :disabled="accountProfiles === null || deletingProfiles"
          @click="closeProfileDeletion"
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          class="p-button-danger"
          :disabled="accountProfiles === null || deletingProfiles"
          @click="deleteProfiles"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="displayForceProfileDeletionDialog"
      header="Profile deletion"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      :modal="true"
      @hide="closeProfileDeletionForced"
    >
      <p>
        Select the profiles you want to delete for account <strong>{{ selectedAccount.email }}</strong>. If these profile are present in other accounts, <strong>data will also be deleted for those accounts.</strong>
      </p>
      <div v-if="accountProfiles === null">
        <ProgressSpinner />
      </div>
      <MultiSelect
        v-else
        v-model="selectedProfiles"
        data-key="profileId"
        class="w-6"
        :options="accountProfiles"
        option-value="profileId"
        placeholder="Select profiles to delete..."
        :option-label="getProfileName"
        :filter="true"
        display="chip"
      >
        <template #option="slotProps">
          <span>{{ `${slotProps.option.name} - ${slotProps.option.countryCode}` }}</span><span class="entity-id">{{ `${slotProps.option.entityId.replace("ENTITY", "")}` }}</span>
        </template>
      </MultiSelect>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-secondary"
          autofocus
          :disabled="accountProfiles === null || deletingProfiles"
          @click="closeProfileDeletionForced"
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          class="p-button-danger"
          :disabled="accountProfiles === null || deletingProfiles"
          @click="deleteProfilesForced"
        />
      </template>
    </Dialog>
    <Button
      label="Generate execution report"
      class="p-button-primary mb-3"
      autofocus
      :disabled="generatingReport"
      @click="generateHistoryExecutionReport"
    />
    <DataTable
      v-model:filters="filters"
      v-model:contextMenuSelection="selectedAccount"
      :value="accounts"
      responsive-layout="scroll"
      :scrollable="true"
      scroll-height="800px"
      scroll-direction="both"
      :global-filter-fields="['email']"
      context-menu
      :rows="20"
      paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows-per-page-options="[10, 20, 50]"
      :paginator="true"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @rowContextmenu="onRowContextMenu"
    >
      <template #empty>
        No data found.
      </template>

      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Search by email..."
            />
          </span>
        </div>
      </template>

      <Column
        field="email"
        header="Email"
        :sortable="true"
        frozen
        class="account-email"
      />
      <Column
        field="accountLinked"
        header="Linked"
        :sortable="true"
      >
        <template #body="slotProps">
          <span>{{ slotProps.data.accountLinked ? 'Yes' : 'No' }}</span>
        </template>
      </Column>
      <Column
        field="type"
        header="Type"
        :sortable="true"
      />
      <Column
        field="enabledProfiles"
        header="Enabled Profiles"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ statistics[slotProps.data.id]?.enabledProfiles ?? 0 }}
        </template>
      </Column>
      <Column
        field="totalProfiles"
        header="Total Profiles"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ statistics[slotProps.data.id]?.totalProfiles ?? 0 }}
        </template>
      </Column>
      <Column
        field="allowedProfiles"
        header="Allowed profiles"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ statistics[slotProps.data.id]?.maxNumberOfProfiles || "Unlimited" }}
        </template>
      </Column>
      <Column
        field="metricsEnabled"
        header="Metrics Enabled"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ statistics[slotProps.data.id]?.metricSetup ? 'Yes' : 'No' }}
        </template>
      </Column>
      <Column
        field="bidrulesEnabled"
        header="Bidrules Enabled"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ statistics[slotProps.data.id]?.bidrulesSetup ? 'Yes' : 'No' }}
        </template>
      </Column>
      <Column
        field="adSpent"
        header="Ad spend"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ `${statistics[slotProps.data.id]?.adSpendPercentage.toFixed(2)}%` }}
        </template>
      </Column>
      <Column
        field="lastLogin"
        header="Last Login"
        :sortable="true"
        class="account-login"
      >
        <template #body="slotProps">
          <span>{{ formatDate( slotProps.data.lastLogin) }}</span>
        </template>
      </Column>
      <Column
        field="createdAt"
        header="Creation Date"
        :sortable="true"
        class="account-login"
      >
        <template #body="slotProps">
          <span>{{ formatDate(slotProps.data.createdAt) }}</span>
        </template>
      </Column>
      <Column
        field="plan"
        header="Plan"
        :sortable="true"
      >
        <template #body="slotProps">
          <div :class="'plan-badge status-' + getPricingStatus(slotProps.data.pricing)">
            {{ getPlanName(slotProps.data.pricing) }}
          </div>
        </template>
      </Column>
      <Column
        field="plan"
        header=""
        :sortable="false"
        class="justify-content-center"
      >
        <template #body="slotProps">
          <router-link
            :to="{name:'dashboard', query: {'mockAccountId': slotProps.data.id}}"
            target="_blank"
          >
            <Button
              type="button"
              label="Access account"
              class="p-button-sm p-button-secondary"
            />
          </router-link>
        </template>
      </Column>
    </DataTable>
    <ContextMenu
      ref="cm"
      :model="menuModel"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { FilterMatchMode } from 'primevue/api';
import A2CAAuthorizedClient from '../../../api/a2caAuthorizedClient';

export default {
  components: { },
  inject: ['query'],
  props: {
  },
  data() {
    return {
      statistics: new Map(),
      loading: false,
      updateKey: 0,
      selectedAccount: null,
      accounts: null,
      displayProfileDeletionDialog: false,
      displayForceProfileDeletionDialog: false,
      accountProfiles: null,
      selectedProfiles: [],
      deletingProfiles: false,
      generatingReport: false,
      menuModel: [
        { label: 'Delete account', icon: 'pi pi-fw pi-user', command: () => this.deleteAccount(this.selectedAccount) },
        { label: 'Delete profiles...', icon: 'pi pi-fw pi-users', command: () => this.openProfileDeletion(this.selectedAccount) },
        { label: 'Delete profiles (forced)...', icon: 'pi pi-fw pi-bin', command: () => this.openForceProfileDeletion(this.selectedAccount) },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

      },
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  async created() {
    this.loading = true;

    const client = new A2CAAuthorizedClient(this.query);
    const adminAccounts = await client.getAdminAccounts('');
    this.accounts = adminAccounts;
    this.loading = false;

    const statisticsResponse = await client.getAdminAccountsStatistics(adminAccounts);

    const statsMap = statisticsResponse.statistics.reduce((map, stats) => {
      /* eslint-disable-next-line */
        map[stats.accountId] = stats;
      return map;
    }, {});

    this.statistics = statsMap;
  },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    goToProductAds(accountId) {
      this.$router.push({ name: 'adminProductAds', params: { accountId } });
    },
    formatDate(date) {
      return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : 'No data';
    },
    onRowContextMenu(event) {
      this.$refs.cm.show(event.originalEvent);
    },
    deleteAccount(account) {
      this.$confirm.require({
        message: `Are you sure you want to delete account ${account.email}? All settings, downloads and bid rules will be deleted.`,
        header: 'Delete Account Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: () => {
          const client = new A2CAAuthorizedClient(this.query);
          client.deleteAccount(account.id).then((r) => {
            this.$toast.add({ severity: 'error', summary: 'Account Deleted', detail: `${account.email} was deleted.` });
            this.accounts = r;
          }).catch(() => {
            this.$toast.add({ severity: 'error', summary: 'Could not delete account', detail: `${account.email} could not be deleted.` });
          });
          this.selectedAccount = null;
        },
        reject: () => {
          this.selectedAccount = null;
        },
      });
      this.selectedAccount = null;
    },
    async openProfileDeletion(account) {
      const client = new A2CAAuthorizedClient();
      this.displayProfileDeletionDialog = true;
      const accountProfiles = await client.getAccountProfiles(account.id);
      this.accountProfiles = accountProfiles;
      this.selectedAccount = account;
    },
    async openForceProfileDeletion(account) {
      const client = new A2CAAuthorizedClient();
      this.displayForceProfileDeletionDialog = true;
      const accountProfiles = await client.getAccountProfiles(account.id);
      this.accountProfiles = accountProfiles;
      this.selectedAccount = account;
    },
    async deleteProfiles() {
      this.deletingProfiles = true;
      const client = new A2CAAuthorizedClient();
      await client.deleteAccountProfiles(this.selectedAccount, this.selectedProfiles, false);
      this.$toast.add({ severity: 'success', summary: 'Profiles deleted', detail: 'The selected profiles have been deleted.' });
      this.accountProfiles = null;
      this.selectedAccount = null;
      this.selectedProfiles = [];
      this.deletingProfiles = false;
      this.displayProfileDeletionDialog = false;
    },
    async deleteProfilesForced() {
      this.deletingProfiles = true;
      const client = new A2CAAuthorizedClient();
      await client.deleteAccountProfiles(this.selectedAccount, this.selectedProfiles, true);
      this.$toast.add({ severity: 'success', summary: 'Profiles deleted', detail: 'The selected profiles have been deleted.' });
      this.accountProfiles = null;
      this.selectedAccount = null;
      this.selectedProfiles = [];
      this.deletingProfiles = false;
      this.displayForceProfileDeletionDialog = false;
    },
    async generateHistoryExecutionReport() {
      this.generatingReport = true;
      const client = new A2CAAuthorizedClient();
      await client.generateBidRuleExecutionHistoryReport();
      this.$toast.add({ severity: 'success', summary: 'Report is being generated', detail: 'Report is being generated. An email will be sent when the task finishes.' });
      this.accountProfiles = null;
      this.selectedAccount = null;
      this.selectedProfiles = [];
      this.generatingReport = false;
      this.displayProfileDeletionDialog = false;
    },
    closeProfileDeletion() {
      this.accountProfiles = null;
      this.selectedAccount = null;
      this.selectedProfiles = [];
      this.displayProfileDeletionDialog = false;
    },
    closeProfileDeletionForced() {
      this.accountProfiles = null;
      this.selectedAccount = null;
      this.selectedProfiles = [];
      this.displayForceProfileDeletionDialog = false;
    },
    getProfileName(profile) {
      return `${profile.name} - ${profile.countryCode} - ${profile.entityId.replace('ENTITY', '')}`;
    },
    getPricingStatus(pricing) {
      if (pricing.subscriptionActive) {
        return 'ACTIVE';
      }

      if (pricing?.pricingPlan) {
        return 'CANCELLED';
      }

      return 'NONE';
    },
    getPlanName(pricing) {
      if (pricing.status === 'NO_PLAN_NEEDED') {
        return 'UNLIMITED';
      }
      if (pricing.status === 'TRIAL') {
        return 'Free trial';
      }
      return pricing?.pricingPlan ? pricing.pricingPlan.planType : 'NO PLAN';
    },
  },
};
</script>
<style>
.account-email {
  min-width: 250px;
}
.account-login {
  min-width: 180px;
}
th, td {
  width: 120px;
}
</style>
<style scoped lang="scss">
@import "../../../assets/styles/dashboard/plan.scss";
</style>
